/* ----------------------------------------------------------------------------
sound player
---------------------------------------------------------------------------- */
export default class SoundPlayer extends HTMLElement {

  playable() {
    this.toggle.addEventListener('click', (e) => {
      const pressed = e.target.getAttribute('aria-pressed') === 'true';
      e.target.setAttribute('aria-pressed', String(!pressed));
      this.toggle.classList.toggle('--active');
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    });
    this.audio.addEventListener("timeupdate", () => {
      // loop at -1 seconds
      if (this.audio.currentTime > this.audio.duration - 1) {
        this.audio.currentTime = 1;
        this.audio.play();
      }
    });
  }

  connectedCallback() {
    this.toggle = this.querySelector('button');
    this.audio = this.querySelector('audio');
    this.playable();
  }
}

customElements.define('sound-player', SoundPlayer);
